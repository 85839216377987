import OpenFormData from './types';

const formData: OpenFormData[] = [
  {
    label: 'Name',
    type: 'text',
    name: 'firstName',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
  },
  {
    label: 'Phone (optional)',
    type: 'tel',
    name: 'phone',
  },
  {
    label: 'Your Message (optional)',
    type: 'textarea',
    name: 'text',
  },
];

export default formData;
