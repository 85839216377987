import React from 'react';
import { ErrorMessage, useField } from 'formik';

export interface FormFieldProps {
  name: string;
  label: string;
  key: number;
  type: string;
}

const FormField = ({ label, ...props }: FormFieldProps) => {
  const [field, meta] = useField(props);
  const className = meta.touched && meta.error ? 'invalid' : meta.touched && !meta.error ? 'valid' : '';

  return (
    <label htmlFor={field.name}>
      {label}
      {label !== 'Your Message (optional)' ? (
        <input className={className} {...field} {...props} />
      ) : (
        <textarea className={className} {...field} {...props} />
      )}

      {meta.touched && meta.error ? (
        <ErrorMessage component="div" name={field.name} className="error" />
      ) : meta.touched && !meta.error ? (
        <p className="success">Success</p>
      ) : null}
    </label>
  );
};

export default FormField;
