import React, { useEffect, useState } from 'react';
import { OpenFormProps } from './types';
import { BTN_STYLES, Button } from '../Button/Button';
import './OpenForm.scss';
import { Formik, Form } from 'formik';
import openFormInitialValues from './openFormInitialValues';
import OpenFormSchema from './openFormSchema';
import { useFirebaseDatabase } from '../../../hooks/useFirebaseDatabase';
import ReCAPTCHA from 'react-google-recaptcha';
import FormField from './FormField/FormField';
import formData from './defaultData';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import { useSendGrid } from '../../../hooks/useSendGrid';

const OpenForm = ({ data = formData, title = 'Contact Us', onClose }: OpenFormProps) => {
  const { saveContact } = useFirebaseDatabase();
  const [recaptchaRef, setRecaptchaRef] = useState<any>(null);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);
  const [thankYouPopup, setThankYouPopup] = useState<boolean>(false);
  const [isLightMode, setIsLightMode] = useState<boolean>(false);
  const { sendEmail } = useSendGrid();

  useEffect(() => {
    if (document.querySelector('.overlay')) {
      setOpenFormPopup(true);
    }

    if (document.querySelector('.mainHome')) {
      setIsLightMode(true);
    }
  }, []);

  return (
    <Formik
      initialValues={openFormInitialValues}
      validationSchema={OpenFormSchema}
      onSubmit={async (values, actions) => {
        try {
          const token = await recaptchaRef.executeAsync();

          if (token) {
            await saveContact(values);
            recaptchaRef.reset();
            actions.resetForm();
            setThankYouPopup(true);
            await sendEmail(values);

            return openFormPopup && onClose;
          }

          throw new Error('Try once more. Are you robot))');
        } catch (error) {
          console.error(`Submit contact : ${error.message}`);
        }
      }}
    >
      {() => {
        return (
          <Form className="contact__form" style={{ minHeight: thankYouPopup ? '687px' : 'auto' }}>
            {openFormPopup && (
              <img
                loading="lazy"
                className="modalCross"
                src={SVG_ICONS_SHARED.CLOSE_CROSS}
                onClick={onClose}
                alt="close icon"
              />
            )}
            <div className={thankYouPopup ? 'form__contentWrapper hideContent' : 'form__contentWrapper showContent'}>
              <h4>{title}</h4>
              {data.map(({ label, name, type }, index) => {
                return <FormField key={index} label={label} name={name} type={type} />;
              })}
              <ReCAPTCHA
                theme="dark"
                ref={(ref) => setRecaptchaRef(ref)}
                sitekey={process.env.RECAPTCHA_SITE_KEY || ''}
                size="invisible"
              />
              <Button title="Send Message" type="submit" className={BTN_STYLES.BTN_PRIMARY_SMALL} />
            </div>

            {thankYouPopup && (
              <div className={isLightMode ? 'thankYou__overlay lightMode' : 'thankYou__overlay darkMode'}>
                <div className="thankYou__wrapper">
                  <div className="thankYou__contentWrapper">
                    <img
                      loading="lazy"
                      className="thankYou__approvedSign"
                      src={SVG_ICONS_SHARED.STATUS_SUCCESS}
                      alt="Approved sign"
                    />
                    <h5 className="thankYou__title">Thank You For Getting In Touch!</h5>
                    <p className="thankYou__description">
                      Our specialist will connect with you via email as soon as possible!
                    </p>
                    <Button
                      type="button"
                      className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
                      title="Okay"
                      onClick={() => (openFormPopup && thankYouPopup ? onClose && onClose() : setThankYouPopup(false))}
                    />
                  </div>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default OpenForm;
