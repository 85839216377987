import { useState } from 'react';

const options = {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
    'X-RapidAPI-Host': process.env.RAPID_API_HOST || '',
    'X-RapidAPI-Key': process.env.RAPID_API_KEY || '',
  },
};

export const useSendGrid = () => {
  const [uploading, setUploading] = useState(false);

  async function sendCv(url: string) {
    try {
      setUploading(true);

      const template = {
        personalizations: [
          {
            to: [
              {
                email: process.env.OWNER_EMAIL,
              },
            ],
            subject: 'You can read new resume',
          },
        ],
        from: {
          email: process.env.FALLBACK_EMAIL,
        },
        content: [
          {
            type: 'text/html',
            value: `See my resume: <a href="${url}">Ссылка на резюме</a>`,
          },
        ],
      };

      await fetch('https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send', {
        ...options,
        body: JSON.stringify(template),
      });
    } catch (error) {
      console.error(`sendEmail :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  async function sendEmail(form: { [key: string]: string }) {
    try {
      setUploading(true);
      const clientData = {
        NAME: form.firstName,
        PHONE: form.phone,
        EMAIL: form.email,
        TEXT: form.text,
      };
      const template = {
        personalizations: [
          {
            to: [
              {
                email: process.env.OWNER_EMAIL,
              },
            ],
            subject: 'You can read new email',
          },
        ],
        from: {
          email: process.env.FALLBACK_EMAIL,
        },
        content: [
          {
            type: 'text/plain',
            value: JSON.stringify(clientData, null, 2).replace('{', ' ').replace('}', ' '),
          },
        ],
      };

      await fetch('https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send', {
        ...options,
        body: JSON.stringify(template),
      });
    } catch (error) {
      console.error(`sendEmail :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  return {
    sendCv,
    sendEmail,
    uploading,
  };
};
