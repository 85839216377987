import * as Yup from 'yup';

const nameCheck = /^[A-ZА-ЯЁ ]+$/i;
const phoneCheck = /^[0-9+()-]+$/;
const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const OpenFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameCheck, 'Please enter only letters')
    .min(2, 'Please use at least 2 characters')
    .max(160, 'Please use no more 160 characters')
    .required('This field is required'),
  email: Yup.string().matches(emailCheck, 'Please enter a valid email address').required('This field is required'),
  phone: Yup.string().matches(phoneCheck, 'Phone number is invalid'),
});

export default OpenFormSchema;
